import React from "react"
import get from "lodash.get"

export interface PlainSelectorProps {
  title: string
  textData: {
    title: string
    text: string
  }[]
  img: string
  img_bg: string
}

export const PlainSelector: React.FC<PlainSelectorProps> = ({
  title,
  textData = [],
  img = "shield_1.svg",
  img_bg = "shield-bg.svg",
}) => {
  return (
    <section
      className="uk-section uk-section-default uk-overflow-hidden"
      data-uk-scrollspy="target: h2, h3, p; cls: uk-animation-slide-bottom-medium; delay: 100"
    >
      <div className="uk-container uk-position-relative">
        <div className="uk-child-width-1-1" data-uk-grid>
          <div>
            <h2 className="uk-text-center">{title}</h2>
          </div>

          <div>
            <div
              className="uk-child-width-1-1 uk-child-width-1-2@s uk-flex-middle"
              data-uk-grid
            >
              <div className="uk-flex uk-flex-center uk-position-relative">
                <img
                  className="uk-width-1-2 uk-width-1-1@s uk-position-absolute no-select"
                  src={img_bg}
                  alt=""
                  data-uk-parallax="y: 100, -100; scale: 1.5; easing: 0"
                />
                <div className="uk-width-1-2 uk-width-2-3@s uk-position-relative uk-margin-large-top">
                  <img
                    className="no-select"
                    src={img}
                    alt=""
                    data-uk-parallax="y: -100"
                  />
                </div>
              </div>
              <div className="uk-position-relative">
                <div className="uk-child-width-1-1" data-uk-grid>
                  {textData.map((block, index) => (
                    <div key={index.toString()}>
                      <h3 className="primary">{block.title}</h3>

                      {block.text.includes("a href") ? (
                        <p dangerouslySetInnerHTML={{ __html: block.text }} />
                      ) : (
                        <p>{block.text}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const PlainSelectorBlock = {
  label: "Plain Multi-item",
  name: "plain",
  key: "plainboi",
  fields: [
    {
      name: "title",
      label: "Title",
      component: "text",
    },
    {
      name: "img",
      label: "Image",
      component: "image",
      parse: (filename) => filename,
      uploadDir: () => "/static/",
      previewSrc: (formValues, { input }) => {
        const path = input.name.replace("rawFrontmatter", "frontmatter")
        const gastbyImageNode = get(formValues, path)
        if (!gastbyImageNode) return ""
        //specific to gatsby-image
        return gastbyImageNode
      },
    },
    {
      name: "img_bg",
      label: "Image Effect/ Bg",
      component: "image",
      parse: (filename) => filename,
      uploadDir: () => "/static/",
      previewSrc: (formValues, { input }) => {
        const path = input.name.replace("rawFrontmatter", "frontmatter")
        const gastbyImageNode = get(formValues, path)
        if (!gastbyImageNode) return ""
        //specific to gatsby-image
        return gastbyImageNode
      },
    },
    {
      name: "data",
      label: "Items",
      component: "group-list",
      description:
        "Different items for the plain multi-item section. Try to limit to max 6.",
      fields: [
        {
          name: "title",
          label: "Title",
          component: "text",
        },
        {
          name: "text",
          label: "Description",
          component: "textarea",
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.title }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        title: "Multi-item Item",
        text: "I'm an item!",
      },
    },
  ],
}
