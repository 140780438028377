import React from "react"
import get from "lodash.get"

export interface PhoneCarouselProps {
  title: string
  reverse: boolean
  use_test: boolean
  testimonial: {
    title: string
    author: string
    text: string
    bg_decoration: boolean
  }
  data: {
    title: string
    text: string
    img: string
  }[]
  use_dark: boolean
  shapes: string
  other_section_classes: {
    remove_padding_top: boolean
    remove_padding_bot: boolean
    extra_padding_bot: boolean
  }
  id: string
  use_different_header: boolean
  use_custom_footer?: boolean
}

export const shapesArray = [
  <>
    <div
      className="shape1"
      data-uk-parallax="scale: .75; x: 2vw, -50vw; y: 2vh, 50vh"
    ></div>
    <div
      className="shape2 uk-position-right"
      data-uk-parallax="scale: 1.25; x: 5, 25vh; y: 30, 0vh; rotate: 45deg, 120deg"
    ></div>
  </>,
  <>
    <div
      className="shape3"
      data-uk-parallax="scale: 1.25; x: 0, 100vw; y: -100vh; rotate: 45deg, 120deg"
    ></div>
  </>,
  <>
    <div
      className="shape4"
      data-uk-parallax="x: -5vw, -5vw; y: 0, 50vh; easing: 0"
    ></div>
  </>,
  <>
    <div
      className="shape5"
      data-uk-parallax="scale: 1.25; x: -5vw, -25vw; y: 150, 0; opacity: 0; easing: 0"
    ></div>
    <div
      className="shape6 uk-position-right"
      data-uk-parallax="scale: 1.25; x: 0, 25vw; y: 30vh, 0; opacity: 0; easing: 0"
    ></div>
  </>,
  <>
    <div
      className="shape1"
      data-uk-parallax="scale: .75; x: -100, -500; y: 0, 500; opacity: 0; rotate: 30deg; easing: 0"
    ></div>
    <div
      className="shape2 uk-position-right"
      data-uk-parallax="scale: 1.25; x: 5, 25vh; y: 30, 0vh; rotate: 45deg, 120deg; opacity: 0; easing: 0"
    ></div>
  </>,
  <>
    <div
      className="shape3"
      data-uk-parallax="scale: 1.25; x: -25vw, 100vw; y: 200, 0; rotate: 60deg; opacity: 0; easing: 0"
    ></div>
  </>,
  <>
    <div
      className="shape2 uk-position-bottom-left"
      data-uk-parallax="x: -50%, -50%; y: -30vh; easing: 0"
    ></div>
    <div
      className="shape1 uk-position-top-right"
      data-uk-parallax="x: 50%, 50%; y: -5vh, 30vh; easing: 0"
    ></div>
  </>,
]

export const PhoneCarousel: React.FC<PhoneCarouselProps> = ({
  title,
  reverse,
  use_test,
  testimonial,
  data,
  use_dark,
  shapes,
  other_section_classes,
  id,
  use_different_header,
  use_custom_footer,
}) => {
  let sectionClasses = "uk-section"
  let phoneClass = "device-iphone-x"
  if (use_dark) {
    sectionClasses += " dark uk-section-secondary"
    phoneClass += " spacegrey"
  } else sectionClasses += " uk-section-muted"

  if (other_section_classes) {
    if (other_section_classes.remove_padding_top)
      sectionClasses += " uk-padding-remove-top"
    if (other_section_classes.remove_padding_bot)
      sectionClasses += " uk-padding-remove-bottom"
  }

  return (
    <section
      className={sectionClasses}
      style={
        other_section_classes?.extra_padding_bot ? { paddingBottom: 150 } : {}
      }
      data-uk-scrollspy="target: h2, .tabs li, .animate; cls: uk-animation-slide-bottom-medium; delay: 100"
    >
      <div className="uk-container uk-container-small">
        <div>{parseInt(shapes) === 6 && shapesArray[5]}</div>
        <div className="uk-child-width-1-1 uk-flex-center" data-uk-grid>
          {use_test && (
            <>
              <div
                className="uk-position-relative uk-width-1-1 uk-width-4-5@s animate"
                style={{ marginTop: 100, marginBottom: 50 }}
              >
                {testimonial?.bg_decoration && (
                  <img
                    className="uk-width-1-2 uk-width-1-1@s uk-position-absolute no-select"
                    src="shield-bg.svg"
                    alt=""
                    data-uk-parallax="y: 200; scale: 4; easing: 0"
                    style={{ position: "absolute", top: -100 }}
                  />
                )}
                <blockquote
                  className="review"
                  style={{
                    position: "absolute",
                    transform: "translateY(calc(-50% - 100px))",
                    width: "calc(100% - 90px)",
                  }}
                >
                  <h4>{testimonial?.title}</h4>
                  <p>{testimonial?.text}</p>
                  <cite className="uk-dark">{testimonial?.author}</cite>
                </blockquote>
              </div>

              {/* <div>
                <div className="spacer"></div>
              </div> */}
            </>
          )}

          {!use_different_header && (
            <div className="uk-text-center">
              <h2>{title}</h2>
            </div>
          )}

          <div className="uk-position-relative">
            {/* <div className="shape4" data-uk-parallax="x: -5vw, -5vw; y: 0, 50vh; easing: 0"></div>
            <div className="shape1" data-uk-parallax="scale: .75; x: 2vw, -50vw; y: 2vh, 50vh"></div>
            <div className="shape2 uk-position-right" data-uk-parallax="scale: 1.25; x: 5, 25vh; y: 30, 0vh; rotate: 45deg, 120deg"></div> */}
            {typeof shapesArray[parseInt(shapes) - 1] !== "undefined" &&
              shapes !== "2" &&
              shapes !== "6" &&
              shapesArray[parseInt(shapes) - 1]}
            <div
              className="uk-position-relative uk-flex-center uk-flex-middle"
              data-uk-grid
            >
              <div className="uk-width-1-1 uk-width-2-5@s uk-position-relative uk-flex uk-flex-center uk-flex-middle">
                <div className="uk-width-2-3 uk-width-1-1@s">
                  <div className={phoneClass}>
                    <div className="device-frame">
                      <div className="device-content">
                        <ul className="uk-switcher" id={id}>
                          {data &&
                            data.map((d, index) => (
                              <li key={index.toString()}>
                                <img
                                  alt=""
                                  className="no-select"
                                  src={`/${d.img}`}
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="device-btns"></div>
                    <div className="device-power"></div>
                  </div>
                </div>
              </div>

              <div
                className={`uk-width-1-1 uk-width-3-5@s tabs ${
                  reverse && "uk-flex-first@s"
                }`}
              >
                {use_different_header && <h2>{title}</h2>}
                <ul
                  data-uk-switcher={`connect: #${id}; animation: uk-animation-fade`}
                >
                  {data &&
                    data.map((d, index) => (
                      <li key={index.toString()}>
                        <a href="">
                          <h4>{d.title}</h4>
                          <p>{d.text}</p>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          {use_custom_footer && (
            <>
              <div data-uk-scrollspy="target: ul; cls: uk-animation-slide-bottom-medium; delay: 100">
                <ul className="merchants">
                  <li data-uk-parallax="y: 0, -75%; easing: 0">
                    <img src="/amazon.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -175%; easing: 0">
                    <img src="/apple.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -100%; easing: 0">
                    <img src="/banana-republic.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -200%; easing: 0">
                    <img src="/bed-bath-and-beyond.jpeg" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -125%; easing: 0">
                    <img src="/best-buy.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -325%; easing: 0">
                    <img src="/dell.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -150%; easing: 0">
                    <img src="/delta.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -250%; easing: 0">
                    <img src="/ebay.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -175%; easing: 0">
                    <img src="/hyatt.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -375%; easing: 0">
                    <img src="/jcrew.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -200%; easing: 0">
                    <img src="/kohls.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -300%; easing: 0">
                    <img src="/lowes.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -225%; easing: 0">
                    <img src="/macys.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -425%; easing: 0">
                    <img src="/marriott.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -250%; easing: 0">
                    <img src="/mcdonalds.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -350%; easing: 0">
                    <img src="/microsoft.jpg" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -250%; easing: 0">
                    <img src="/nordstrom-rack.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -475%; easing: 0">
                    <img src="/sephora.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: 0, -275%; easing: 0">
                    <img src="/starbucks.png" alt="" />
                  </li>
                  <li data-uk-parallax="y: -75%, -400%; easing: 0">
                    <img src="/target.png" alt="" />
                  </li>
                </ul>
                <div className="uk-margin-xlarge-bottom"></div>
              </div>
            </>
          )}
          <div>{parseInt(shapes) === 2 && shapesArray[1]}</div>
        </div>
      </div>
    </section>
  )
}

export const PhoneCarouselBlock = {
  label: "Phone Carousel",
  name: "phonecarousel",
  key: "phcar",
  fields: [
    {
      name: "id",
      label: "ID",
      component: "text",
      description: "Must be unique on a given page",
    },
    {
      name: "title",
      label: "Title",
      component: "text",
    },
    {
      name: "use_test",
      label: "Show testimonial? (Above)",
      component: "toggle",
    },
    {
      name: "testimonial",
      label: "Testimonial Info",
      component: "group",
      fields: [
        {
          label: "Title",
          name: "title",
          component: "text",
        },
        {
          label: "Text",
          name: "text",
          component: "text",
        },
        {
          label: "BG Decoration?",
          name: "bg_decoration",
          component: "toggle",
        },
      ],
    },
    {
      name: "data",
      label: "Carousel Items",
      component: "group-list",
      description: "Items for phone carousel",
      fields: [
        {
          name: "title",
          label: "Title",
          component: "text",
        },
        {
          name: "text",
          label: "Description",
          component: "textarea",
        },
        {
          name: "img",
          label: "Image",
          component: "image",
          parse: (filename) => filename,
          uploadDir: () => "/static/",
          previewSrc: (formValues, { input }) => {
            const path = input.name.replace("rawFrontmatter", "frontmatter")
            const gastbyImageNode = get(formValues, path)
            if (!gastbyImageNode) return ""
            //specific to gatsby-image
            return gastbyImageNode
          },
        },
      ],
      itemProps: (item: any) => ({ key: item.key, label: item.title }),
      defaultItem: {
        key: Math.random().toString(36).substr(2, 9),
        title: "New Carousel Item",
        text: "I'm a carousel item!",
        image: "bills.jpeg",
      },
    },
    {
      name: "shapes",
      label: "Shape Choice",
      component: "text",
    },
    {
      name: "other_section_classes",
      label: "Other Options",
      component: "group",
      fields: [
        {
          name: "remove_padding_top",
          label: "Remove Section Top Padding",
          component: "toggle",
        },
        {
          name: "remove_padding_bot",
          label: "Remove Section Bot Padding",
          component: "toggle",
        },
        {
          name: "extra_padding_bot",
          label: "Extra Section Bot Padding",
          component: "toggle",
        },
      ],
    },
    {
      name: "reverse",
      label: "Reversed?",
      component: "toggle",
    },
    {
      name: "use_different_header",
      label: "Use Alt Header?",
      component: "toggle",
    },
    {
      name: "use_custom_footer",
      label: "Use Custom Footer?",
      component: "toggle",
    },
    {
      name: "use_dark",
      label: "Use Dark Mode?",
      component: "toggle",
    },
  ],
}
