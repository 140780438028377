import React from "react"

const CtaFooter: React.FC = () => (
  <section className="uk-section uk-section-muted">
    <div className="uk-container uk-container-small">
      <div
        className="uk-child-width-1-1 uk-child-width-1-2@s uk-flex-middle uk-text-center"
        data-uk-grid
      >
        <div className="uk-position-relative uk-flex uk-flex-center">
          <div
            className="shape5"
            data-uk-parallax="y: -50; rotate: 15deg"
          ></div>
          <div
            className="shape6"
            data-uk-parallax="x: -100, -150; y: 50, 100; rotate: 15deg"
          ></div>
          <div className="uk-width-1-1 uk-position-absolute">
            <img
              src="/card.png"
              alt=""
              data-uk-parallax="y: -100; rotate: -15deg"
            />
          </div>
          <div className="uk-width-1-1 uk-position-relative">
            <img
              src="/money.png"
              alt=""
              data-uk-parallax="y: -200; scale: 2; rotate: -20deg"
            />
          </div>
        </div>

        <div className="uk-flex-first@s uk-position-relative">
          <div className="uk-flex-center uk-grid-small" data-uk-grid>
            <div className="uk-width-1-1 margin-collapse">
              <h2>Get More From Your Cards</h2>
              <h3 className="primary">Download the App Today</h3>
            </div>
            <div>
              <a href="https://apps.apple.com/us/app/maxrewards-earn-more-rewards/id1435710443">
                <img
                  alt="Download on the App Store"
                  className="height-xsmall animate"
                  src="/download-maxrewards-app-store.svg"
                />
              </a>
            </div>

            <div>
              <a href="https://play.google.com/store/apps/details?id=com.maxrewards&hl=en_US&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Get it on Google Play"
                  className="height-xsmall animate"
                  src="/download-maxrewards-play-store.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CtaFooter
